<template>
  <fragment>
    <b-input v-model="innerValue" v-bind="bindings" v-on="listeners"></b-input>
    <div class="clear-input">
      <clear-input-icon v-if="state === false" class="clear-input-icon" @click="innerValue = ''">
      </clear-input-icon>
    </div>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-frag'
import ClearInputIcon from 'assets/clear-input.svg?inline'

export default {
  name: 'TInput',

  components: {
    Fragment,
    ClearInputIcon
  },

  inheritAttrs: false,

  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: [String, Number, null]
    },
    state: {
      type: [Boolean, null],
      default: null
    }
  },

  computed: {
    innerValue: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    },

    bindings() {
      const { value, ...rest } = this.$attrs
      return { ...rest, state: this.state }
    },

    listeners() {
      const { input, ...rest } = this.$listeners
      return rest
    }
  }
}
</script>

<style lang="scss">
.clear-input {
  top: 0;
  position: relative;

  &-icon {
    position: absolute;
    right: 10px;
    bottom: -50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
